.map {
  height: 400px;
  width: 100%;
}
.k-animation-container {
  z-index: 10003 !important;
}
/* .k-dialog-wrapper {
  z-index: 100004
} */
.orderDetailImgOnHover:hover{
  height: 300px;
  width: 300px;
}
.ReactTable .rt-td, .ReactTable .rt-th {
  font-weight: 500;
 }

#react-select-3-listbox {
  z-index: 10003 !important;
}
.swal2-container {
  z-index: 10003 !important;
}
