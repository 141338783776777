/*!

=========================================================
* Material Dashboard PRO React - v1.10.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// variables and mixins
@import 'material-dashboard-pro-react/variables';
@import 'material-dashboard-pro-react/mixins';
@import 'material-dashboard-pro-react/shadows';

// plugin css
@import 'material-dashboard-pro-react/plugins/plugin-react-tagsinput';
@import 'material-dashboard-pro-react/plugins/plugin-nouislider';

// Core CSS
